import React from 'react';
import Main from '../../containers/Layout';
import cbdPaymentsPageContent from '../../../content/pages/marketing/cbd-payments.yml';
import CbdPaymentsContainer from '@/containers/Cbd-payments';

export interface CbdPaymentsPageProps {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    title5: string;
    title6: string;
    description1: string;
    description2: string;
    description3?: string;
    button: { label: string; url: string };
  };
  viewport2: {
    title: string;
    boldDescription1: string;
    boldDescription2: string;
    description: string;
    paymentsCards: { title: string; text: string; image: string }[];
  };
  viewport3: {
    title1: string;
    title2: string;
    titleAndTextItems: {
      title: string;
      text: string;
    }[];
  };
  viewport4: {
    title1: string;
    title2: string;
    titleAndTextItems: {
      title: string;
      text: string;
    }[];
  };
  viewport5: {
    title1: string;
    title2: string;
    title3: string;
    titleAndTextItems: {
      title: string;
      text: string;
    }[];
  };
  viewport6: {
    title1: string;
    title2: string;
    title3: string;
    titleAndTextItems: {
      title: string;
      text: string;
    }[];
  };
  viewport7: {
    title: string;
    titleAndTextItems: {
      title: string;
      text: string;
    }[];
  };
  viewport8: {
    title: string;
    subtitle1: string;
    eCommerceCards: {
      image: string;
    }[];
    subtitle2: string;
    marketplaceCards: {
      image: string;
    }[];
    subtitle3: string;
    accountancyCards: {
      image: string;
    }[];
  };
  viewport9: {
    bannerLabel1: string;
    bannerLabel2: string;
    bannerLabel3: string;
    bannerDescription: string;
    button: {
      label: string;
      url: string;
    };
  };
}
const CbdPayments: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
  } = cbdPaymentsPageContent as unknown as CbdPaymentsPageProps;
  return (
    <Main>
      <CbdPaymentsContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
          viewport8,
          viewport9,
        }}
      />
    </Main>
  );
};

export default CbdPayments;
